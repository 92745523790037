nav
{
	position:         relative;
	background-color: #555555;
}

nav ul
{
	padding: 0;
}

nav ul li
{
	display: inline-block;
}

nav > ul > li > a,
nav > ul > li > button
{
	display:          block;
	padding:          .9375rem 2.125rem;
	
	border:           0;
	background-color: inherit;
	height:           auto;
	
	font:             inherit;
	font-weight:      600;
	font-size:        .8125rem;
	text-transform:   uppercase;
	color:            #eeeeee;
}

nav > ul > li > button.noAction
{
	cursor: default;
}

nav > ul > li:first-of-type > a
{
	padding-left: 1.375rem;
}

nav > ul > li > a.active,
nav > ul > li.active > button,
nav > ul > li:hover > button,
nav > ul > li > a:focus,
nav > ul > li > button:focus,
nav > ul > li > a:hover,
nav > ul > li > button:hover
{
	background-color: #00937f;
	text-decoration:  none;
}

nav .advancedMenu
{
	width:      100%;
	max-width:  78.75rem;
	box-shadow: 0 10px 25px -15px #000000;
	background: #ffffff;
	position:   absolute;
	color:      #555555;
	z-index:    1;
}

nav .advancedMenu ul
{
	display:                     block;
	width:                       100%;
	
	padding:                     .625rem 1.5625rem .9375rem;
	-webkit-column-break-inside: avoid;
	page-break-inside:           avoid;
	break-inside:                avoid;
}

nav .advancedMenu li
{
	display:     block;
	font-family: roboto condensed, helvetica neue, Helvetica, Arial, lucida grande, sans-serif;
	font-size:   1.125rem;
	font-weight: 400;
	color:       #00937f;
	padding:     .3125rem 0 0;
}

nav .advancedMenu li.text
{
	color:       #555555;
	font-weight: 400;
	font-size:   .875rem;
	padding:     .09375rem 0;
}

nav li:nth-child(2)
{
	position: relative;
}

nav li:nth-child(4) .advancedMenu
{
	width: 18rem;
}

nav .version
{
	font-size: 0.75em;
	color:     #eeeeee;
	position:  absolute;
	right:     1rem;
	padding:   .9375rem 0;
}
