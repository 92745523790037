*
{
	box-sizing: border-box;
}

/* open-sans-regular - latin */
@font-face
{
	font-family:  "Open Sans";
	font-style:   normal;
	font-weight:  400;
	src:          url("./fonts/open-sans-v18-latin-regular.woff2") format("woff2"),
	              url("./fonts/open-sans-v18-latin-regular.woff") format("woff");
	font-display: swap;
}

/* open-sans-600 - latin */
@font-face
{
	font-family:  "Open Sans";
	font-style:   normal;
	font-weight:  600;
	src:          local(""),
	              url("./fonts/open-sans-v18-latin-600.woff2") format("woff2"),
	              url("./fonts/open-sans-v18-latin-600.woff") format("woff");
	font-display: swap;
}

/* roboto-condensed-regular - latin */
@font-face
{
	font-family:  "Roboto Condensed";
	font-style:   normal;
	font-weight:  400;
	src:          url("./fonts/roboto-condensed-v19-latin-regular.woff2") format("woff2"),
	              url("./fonts/roboto-condensed-v19-latin-regular.woff") format("woff");
	font-display: swap;
}

body
{
	margin:                   0;
	font-family:              open sans, helvetica neue, Helvetica, Arial, lucida grande, sans-serif;
	-ms-text-size-adjust:     100%;
	-webkit-text-size-adjust: 100%;
	overflow-y:               scroll;
}

.container
{
	max-width: 78.75rem;
	margin:    0 auto;
	width:     100%;
}

img
{
	max-width:       100%;
	max-height:      100%;
	border:          0;
	image-rendering: optimizeQuality;
}

a
{
	text-decoration: none;
	color:           inherit;
}

a:hover
{
	text-decoration: underline;
}

h1, h2, h3, h4, h5, h6, button, a.btn, input[type="button"]
{
	font-family: roboto condensed, helvetica neue, Helvetica, Arial, lucida grande, sans-serif;
	font-weight: 400;
}

button
{
	font-size:   1rem;
	line-height: 2.375rem;
	text-align:  center;
	height:      2.625rem;
	padding:     .125rem .75rem;
	border:      0;
	background:  #00937f;
	color:       #ffffff;
	cursor:      pointer;
}

button:hover
{
	background-color: #007a69;
}