.ResultsSources
{
	background: #f5f5f5;
	font-size:  .875rem;
}

.ResultsSources h3
{
	margin-bottom: 0.5rem;
}

.ResultsSources ul
{
	list-style-type: none;
}

.ResultsSources ul,
.ResultsSources li
{
	padding: 0;
	margin:  1px 0;
}

.ResultsSources > ul > li
{
	margin: 0 0 0.75rem 0;
}

.ResultsSources > ul > li > label
{
	font-weight:  600;
	padding-left: 0.2rem;
}

.ResultsSources li button
{
	display:          block;
	background-color: transparent;
	color:            #000000;
	cursor:           pointer;
	
	height:           auto;
	line-height:      1.3rem;
	width:            100%;
	text-align:       left;
	padding:          .125rem .25rem;
	padding-left:     1rem;
}

.ResultsSources li button[disabled]
{
	cursor: not-allowed;
	color:  #a3a3a3;
}

.ResultsSources li.loading button
{
	cursor: wait;
}

.ResultsSources li button:not([disabled]):hover,
.ResultsSources li.active button
{
	background-color: #00937f;
	color:            #ffffff;
}

.ResultsSources li.error button div.error
{
	display:             inline-block;
	width:               1rem;
	height:              1rem;
	background-repeat:   no-repeat;
	background-position: 50%;
	background-image:    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M445.215 478.835H66.848c-23.67 0-45.079-12.118-57.25-32.418-12.182-20.3-12.802-44.883-1.663-65.781L198.889 65.387c12.182-20.17 33.548-32.222 57.142-32.222s44.959 12.052 57.152 32.245l190.499 314.433c11.585 21.692 10.966 46.274-1.217 66.575-12.171 20.299-33.58 32.417-57.25 32.417z' fill='%23ff9f19'%3E%3C/path%3E%3Cpath d='M445.215 478.835c23.67 0 45.079-12.118 57.25-32.418 12.182-20.3 12.802-44.883 1.217-66.575L313.184 65.409c-12.193-20.193-33.559-32.245-57.152-32.245v445.67h189.183z' fill='%23f28618'%3E%3C/path%3E%3Cpath d='M256.031 345.294c-9.215 0-16.693-7.477-16.693-16.693V161.675c0-9.215 7.477-16.693 16.693-16.693s16.693 7.477 16.693 16.693v166.926c0 9.216-7.477 16.693-16.693 16.693z' fill='%23486475'%3E%3C/path%3E%3Cpath d='M256.031 412.061c-9.21 0-16.693-7.498-16.693-16.693s7.483-16.693 16.693-16.693 16.693 7.498 16.693 16.693-7.483 16.693-16.693 16.693z' fill='%233c4d5c'%3E%3C/path%3E%3C/svg%3E");
	vertical-align:      middle;
}

.ResultsSources li button span.count
{
	float: right;
}

.ResultsSources li button .loader
{
	position: relative;
	top:      1px;
}