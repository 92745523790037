
.Results .resultlist li
{
	padding: 0.5rem 1rem;
}

.Results .resultlist li:not(:last-of-type)
{
	border-bottom: 1px solid #d4d4d4;
}

.Results .resultlist li a,
.Results .resultlist li button
{
	font-family: roboto condensed, helvetica neue, Helvetica, Arial, lucida grande, sans-serif;
	color:       #00937f;
	font-weight: 400;

	display:     block;
	margin:      0 0 .3125rem;
	font-size:   1rem;
	line-height: 1.25rem;
	max-width:   100%;
	overflow:    hidden;
}

.Results .resultlist li .date
{
	font-style: italic;
	font-size:  0.8em;
}

.Results .resultlist li button
{
	text-align:       left;
	background-color: transparent;
	height:           auto;
	padding:          0;
}

.Results .resultlist li button.loading
{
	cursor: wait;
}

.Results .resultlist li button div.loader
{
	margin:         0 0 0.1rem 0.3rem;
	vertical-align: middle;
}

.Results .resultlist li button:hover
{
	text-decoration: underline;
}

.Results .resultlist li .text
{
	font-size:     0.75rem;
	line-height:   0.95rem;
	margin:        0 0 .3125rem;
	overflow-wrap: break-word;
}

.Results .resultlist li .text p
{
	margin: 0;
}

.Results .resultlist li .text p:not(:first-of-type)
{
	margin-top: 0.3rem;
}

.Results .resultlist li .location
{
	font-style: italic;
	font-size:  0.8em;
	margin:     0 0 .3125rem;
}

.Results .resultlist li em
{
	background-color: #ffffaa;
}
