.Pagination
{
	padding:          .375rem .625rem;
	background-color: #f5f5f5;
	border-top:       1px solid #d4d4d4;
}

.Pagination button
{
	padding:          0 .625rem;
	margin:           0 .0625rem;
	font-size:        .875rem;
	line-height:      1.875rem;
	border-radius:    3px;
	background-color: #ffffff;
	background-image: linear-gradient(to bottom, #ffffff 0%, #f8f8f8 100%);
	display:          inline-block;
	font-weight:      500;
	text-align:       center;
	border:           1px solid #d4d4d4;
	color:            #666666;
	height:           auto;
	min-width:        2.3rem;
}

.Pagination button:not([disabled]):hover
{
	background:   #ffffff;
	color:        #00937f;
	border-color: #00937f;
}

.Pagination button[disabled]
{
	cursor:  not-allowed;
	opacity: 0.5;
}

.Pagination button.active
{
	background-image: none;
	background-color: #00937f;
	color:            #ffffff;
	font-weight:      700;
	cursor:           default;
}

.Pagination span
{
	line-height: 1.875rem;
	margin-left: .625rem;
}

.Pagination div.resultsPerPage
{
	float:   right;
	padding: .375rem 0;
}