
.Sources
{
	width: 100%;
}

.Sources h3 button
{
	margin-left: 1rem;
}

.small .Sources h3 button
{
	display: none;
}

.Sources ul
{
	display:         flex;
	justify-content: space-evenly;
	padding:         0;
	list-style-type: none;
}

.Sources ul .isLoading
{
	text-align: center;
	margin:     2rem;
}

.Sources ul .isLoading div
{
	font-size:  0.9em;
	margin-top: 0.5rem;
}

.overlay .Sources h3
{
	margin: 0;
}

/* Oberste Label pro Liste fettgedruckt */
.Sources > ul > li > label
{
	font-weight: 600;
}

.Sources ul ul
{
	flex-direction: column;
}

.Sources ul li
{
	padding-left: 1.5rem;
	line-height:  1.6rem;
}

.overlay .Sources ul li
{
	padding-right: 1.5rem;
}

.Sources ul *
{
	vertical-align: middle;
}

.Sources ul li span,
.Sources ul li img
{
	margin-left: .25rem;
	user-select: none;
}

.Sources ul li svg
{
	opacity:     0;
	height:      22px;
	width:       22px;
	color:       #00937f;
	margin-left: 0.2rem;

	transition:  opacity 100ms;
}

.Sources ul ul li:hover svg
{
	opacity: 1;
}
.Sources ul ul li svg:hover
{
	color: #007a69;
}

.Sources img
{
	height: 20px;
}

.Sources ul li.disabled
{
	color: #d2d2d2
}

.Sources ul li.img
{
	margin: 0.1rem 0;
}

.Sources li.disabled img
{
	filter: grayscale(1) opacity(0.25);
}
