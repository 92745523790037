
.banner
{
	height:           24px;
	background-color: #f3f3f3;
	
	text-transform:   uppercase;
	font-size:        .6875rem;
	line-height:      1.375rem;
	font-weight:      600;
	color:            #555555;
}

.topBar
{
	padding:    .25rem 0;
	
	text-align: right;
}

.topBar button
{
	background-color: transparent;
	height:           auto;
	line-height:      inherit;
	color:            inherit;
}

.topBar button:hover
{
	text-decoration: underline;
}

.topBar span
{
	margin-right: .75rem;
}

header .main
{
	padding:        .625rem 0;
	padding-bottom: 2.5rem;
}

header span.environment
{
	font-family: roboto condensed, helvetica neue, Helvetica, Arial, lucida grande, sans-serif;
	font-weight: 400;
	font-size:   2em;
	line-height: 1em;
	margin-left: 1rem;
	color:       #00937f;
}
