.App
{
	min-height:     100vh;
	display:        flex;
	flex-direction: column;
}

main
{
	padding:   1rem 0;
	position:  relative;
	flex-grow: 1;
	
	animation: pageChange 175ms normal ease-in-out;
}

@keyframes pageChange
{
	0%
	{
		opacity:   0;
		transform: translateX(-100%);
	}
}
