.Facets
{
	background: #f5f5f5;
    animation:  slideInFacets 150ms normal ease-in-out;
}

@keyframes slideInFacets
{
    0%
    {
        opacity:   0;
        transform: translateY(-10%);
    }
    100%
    {
        opacity:   1;
        transform: translateY(0);
    }
}

.Facets h3
{
	margin-bottom: 0.5rem;
}

.Facets ul
{
	margin:          0;
	padding:         0;
	list-style-type: none;
}

.Facets > ul > li
{
	margin-bottom: .5rem;
	border-bottom: 1px solid #f5f5f5;
}

.Facets button
{
	background-color: transparent;
	color:            #000000;
	padding:          .125rem .25rem;
	height:           auto;
	width:            100%;
	font-size:        1rem;
	line-height:      1.2rem;
	text-align:       left;
}

.Facets button.more
{
	color:        #000000;
	font-size:    0.8em;
	margin-left:  .25rem;
	display:      none;
	padding-left: 0.75rem;
}

.Facets button:hover
{
	background: #00937f;
	color:      #ffffff;
}

.Facets li.expanded button.more
{
	display: block;
}

.Facets ul button.more:hover
{
	text-decoration: underline;
}

.Facets ul.values
{
	font-size: 0.8em;
	display:   none;
}

.Facets li.expanded ul.values
{
	display: block;
}

.Facets ul.values li button
{
	display:          block;
	background-color: transparent;
	color:            #000000;
	cursor:           pointer;
	height:           auto;
	line-height:      1.3rem;
	width:            100%;
	text-align:       left;
	padding:          .25rem;
	padding-left:     1rem;
}

.Facets ul.values li.active button,
.Facets ul.values li button:hover
{
	background-color: #00937f;
	color:            #ffffff;
}

.Facets ul.values .email
{
	font-size: 0.8em;
}

.Facets ul.values .count
{
	float: right;
}
