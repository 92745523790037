footer
{
	background-color: #d4d4d4;
	font-size:        .6875rem;
}

footer ul li
{
	display: inline-block;
	padding: .9375rem 2.125rem;
}

footer ul li:not(:last-of-type)
{
	border-right: 1px solid #555555;
}