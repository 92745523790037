.UserMessages.fixed
{
	position:  fixed;
	left:      50%;
	transform: translateX(-50%);
	z-index:   1;
}

.UserMessage
{
	background-color: #eeeeee;
	font-size:        .875rem;
	line-height:      1.5rem;
	border-radius:    3px;
	
	display:          flex;
	height:           44px;
	margin-top:       16px;
	
	cursor:           pointer;
	user-select:      none;
	
	animation:        slideIn 400ms normal ease-in-out;
}

@keyframes slideIn
{
	0%
	{
		opacity:   0;
		transform: translateY(-100%);
	}
	100%
	{
		opacity:   1;
		transform: translateY(0);
	}
}

.UserMessage:hover
{
	transition-duration: 300ms;
	opacity:             0.4;
}

.UserMessage .icon
{
	height:                    44px;
	width:                     8%;
	border-top-left-radius:    3px;
	border-bottom-left-radius: 3px;
	
	display:                   flex;
	align-items:               center;
}

.UserMessage.error
{
	color: #bd0014;
}

.UserMessage.error .icon
{
	background-color: #bd0014;
	color:            #ffffff;
}

.UserMessage.info
{
	color:      #376d8e;
	background: #dee6eb;
}

.UserMessage.info .icon
{
	background-color: #376d8e;
	color:            #dee6eb;
}

.UserMessage .icon svg
{
	margin: auto;
}

.UserMessage .text
{
	padding: .625rem;
}

button.clearAllUserMessages
{
	float:      right;
	display:    block;
	margin-top: 16px;
	color:      #00937f;
	background: #ffffff;
	border:     1px solid #00937f;
	animation:  slideIn 400ms normal ease-in-out;
}

button.clearAllUserMessages:hover
{
	background:   #00937f;
	color:        #ffffff;
	border-color: #ffffff;
}
