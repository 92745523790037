.Results
{
	display:         flex;
	justify-content: space-between;
}

.Results .sidebar
{
	width: 25%;
}

.Results .searchfield input[type="text"]
{
	width:   100%;
	height:  2.625rem;
	padding: .125rem .75rem;
	border:  1px solid #cccccc;
}

.Results .resultlist
{
	width: 74%;
}

.Results .title,
.Results h3
{
	background-color: #00937f;
	color:            #ffffff;

	font-size:        1.25rem;
	font-weight:      400;
	line-height:      1.35rem;
	height:           2.625rem;
	padding:          .65rem;
}

.Results .title > *
{
	vertical-align: middle;
}

.Results .title > a,
.Results .title > button
{
	float: right;
}

.Results .title > button
{
	padding:     0;
	height:      unset;
	line-height: unset;
}

.Results .title > a:hover,
.Results .title > button:hover
{
	color:  #eeeeee;
	filter: drop-shadow(0px 0px 1px #eeeeee);
}

.Results .title > button:hover
{
	background-color: transparent;
}

.Results .subtitle
{
	position:    relative;
	top:         -0.25rem;
	font-size:   0.75em;
	font-weight: 600;
}

.Results .sidebar .title
{
	margin-top: 1rem;
}

.Results .resultlist .isLoading
{
	text-align: center;
	margin:     2rem;
}

.Results .resultlist .isLoading div
{
	font-size:  0.9em;
	margin-top: 0.5rem;
}

.Results .resultlist ul
{
	list-style-type: none;
	margin:          0;
	padding:         0;
}
