.loader
{
	position: relative;
	display:  inline-block;
}

.loader:before
{
	border-radius:    100%;
	animation:        loader 1s linear infinite;
	width:            100%;
	height:           100%;
	box-sizing:       border-box;
	display:          block;
	content:          "";
	border:           2px solid #cccccc;
	border-top-color: #333333;
}

@keyframes loader
{
	100%
	{
		transform: rotate(360deg)
	}
}
