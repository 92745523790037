.Search:not(.overlay)
{
	position: relative;
}

.Search:not(.small)
{
	padding: 0.5rem;
}

.Search h2
{
	font-size:   1.6em;
	line-height: 1.4em;
	color:       #888888;
	margin:      1rem 0;
}

.Search.small h2
{
	display: none;
}

.Search form
{
	display:        flex;
	flex-direction: column;
	align-items:    center;
	align-content:  center;
	align-self:     center;
}

.Search.small,
.Search.small:not(.overlay) form
{
	display: block;
	height:  2.7rem;
}

.Search:not(.small) form .searchhead
{
	display:     flex;
	align-items: center;
}

.Search.overlay form .searchhead
{
	display:       flex;
	width:         100%;
	margin-bottom: 0.5rem;
}

.Search form .searchhead label
{
	color:        #000000;
	font-weight:  bold;
	font-size:    1rem;
	line-height:  1.375rem;
	margin-right: 1rem;
}

.Search.small form .searchhead label
{
	display: none;
}

.Search .searchfield
{
	position: relative;
	display:  inline-block;
}

.Search.small .searchfield
{
	display: block;
}

.Search .searchfield svg
{
	pointer-events: none;
	position:       absolute;
	right:          0.5rem;
	top:            0.5rem;
}

.Search .searchfield input[type="text"]
{
	width:   25rem;
	height:  2.625rem;
	padding: .125rem .75rem;
	border:  1px solid #cccccc;
}

.Search.small .searchfield input[type="text"]
{
	width: 100%;
}

.Search.overlay .searchfield
{
	flex-grow: 1;
}

.Search.small:not(.overlay) .Sources,
.Search.small:not(.overlay) button
{
	display: none;
}

.Search.small > div
{
	position: absolute;
}

.Search.small:not(.overlay) > div
{
	width: 100%;
}

.Search.overlay > div
{
	background-color: #ffffff;
	z-index:          1;
	border:           1px solid #dddddd;
	box-shadow:       1px 1px 1px #dddddddd;
	padding:          0.5rem;
	transform:        translate(calc(-0.5rem - 1px), calc(-0.5rem - 1px));
}

.Search.overlay > div .Sources
{
	animation: showOverlay 250ms normal ease-in-out;
}

@keyframes showOverlay
{
	0%
	{
		opacity: 0;
	}
}
